import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`PayPerks founders Arlyn Davich and Jake Peters presented to over one hundred thought leaders from the financial services industry at Anthemis’ Innovation Playground event today in London. The topic was how gamification can educate and engage customers, particularly the underserved. Arlyn and Jake explained how PayPerks uses a combination of education and sweepstakes-based incentives to drive behaviors that are both positive for the lower and middle income segment and profitable for the financial services providers.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.youtube.com/watch?v=Vpd5jmDZjkc"
      }}>{`The Innovation Playground 2012: Gamification in Finance - with Arlyn Davich`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      